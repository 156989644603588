import { useState } from 'react';
import { FiSearch } from 'react-icons/fi';

import './style/style.css';

import Cepfinder from './assets/cepfinder';
import Adressfinder from './assets/adressfinder';

export default function Home() {
  const [componenteAtual, setComponenteAtual] = useState('Cepfinder');

  return (
    <div className="container">
      <section className="formHolder">
        <header className="header">
          <button
            className={`btnchange cepFinder ${componenteAtual === 'Cepfinder' ? 'active' : ''}`}
            onClick={() => setComponenteAtual('Cepfinder')}
          >
            Buscar Endereço através do CEP
          </button>

          <button
            className={`btnchange adressFinder ${componenteAtual === 'Adressfinder' ? 'active' : ''}`}
            onClick={() => setComponenteAtual('Adressfinder')}
          >
            Buscar CEP através do Endereço
          </button>
        </header>

        {componenteAtual === 'Cepfinder' ? <Cepfinder /> : <Adressfinder />}
      </section>
    </div>
  );
}
