import React from 'react';
import { Component, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import api from "../services/api";
import AdressList from './adressList';
import '../style/adressList.css';

const Adressfinder = () => {

  const [uf, setUf] = useState('');
  const [cidade, setCidade] = useState('');
  const [logradouro, setLogradouro] = useState('');

  const [data, setData] = useState({});
  const [enderecos, setEnderecos] = useState([]);

  async function handleSearch() {

    if (uf === '') {

      alert("Preencha o estado!");
      return;

    }
    if (cidade === '') {

      alert("Preencha a cidade!");
      return;

    }
    if (logradouro === '') {

      alert("Preencha o nome da rua!");
      return;

    }
    try {

      const response = await api.get(`${uf}/${cidade}/${logradouro}/json`);
      setData(response.data);
      setEnderecos(response.data);

      setCidade("");
      setLogradouro("");

    } catch {

      alert("OPS! Erro ao buscar");
      setCidade("");
      setLogradouro("");
    }
  }


  return (
    <div className="containerM">
      <div className='element-adress-input'>
        <h1 className="title">Buscador de CEP</h1>

        <div id="adressInput">

          <select className='selectInput' onChange={(e) => setUf(e.target.value)}>

            <option value=''>Selecione o Estado</option>
            <option value='AC'>Acre</option>
            <option value='AL'>Alagoas</option>
            <option value='AP'>Amapá</option>
            <option value='AM'>Amazonas</option>
            <option value='BA'>Bahia</option>
            <option value='CE'>Ceará</option>
            <option value='DF'>Distrito Federal</option>
            <option value='ES'>Espírito Santo</option>
            <option value='GO'>Goiás</option>
            <option value='MA'>Maranhão</option>
            <option value='MT'>Mato Grosso</option>
            <option value='MS'>Mato Grosso do Sul</option>
            <option value='MG'>Minas Gerais</option>
            <option value='PA'>Pará</option>
            <option value='PB'>Paraíba</option>
            <option value='PR'>Paraná</option>
            <option value='PE'>Pernambuco</option>
            <option value='PI'>Piauí</option>
            <option value='RJ'>Rio de Janeiro</option>
            <option value='RN'>Rio Grande do Norte</option>
            <option value='RS'>Rio Grande do Sul</option>
            <option value='RO'>Rondônia</option>
            <option value='RR'>Roraima</option>
            <option value='SC'>Santa Catarina</option>
            <option value='SP'>São Paulo</option>
            <option value='SE'>Sergipe</option>
            <option value='TO'>Tocantins</option>

          </select>

          <input
            className="inputAdress"
            type="text"
            placeholder="Digite a cidade"
            value={cidade}
            onChange={(e) => setCidade(e.target.value)}
          />

          <input
            className="inputAdress"
            type="text"
            placeholder="Digite o nome da rua"
            value={logradouro}
            onChange={(e) => setLogradouro(e.target.value)}
          />

          <button className="buttonSearch" onClick={handleSearch}>
            <FiSearch size={25} color="#541734" />
          </button>

        </div>
      </div>
      <div className='element-adress-handle'>
        {
          Object.keys(data).length > 0 && (
            <main className='adressList'>

              <h2>Lista de endereços</h2>
              <AdressList enderecos={enderecos} />

            </main>
          )
        }
      </div>
    </div>

  );

};

export default Adressfinder;