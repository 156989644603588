import React from 'react';
import { Component, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import api from "../services/api";

const Cepfinder = () => {

  const [input, setInput] = useState('')
  const [cep, setCep] = useState({});

  async function handleSearch() {

    if (input === '') {

      alert("Preencha algum CEP!");
      return;

    }

    try {

      const response = await api.get(`${input}/json`);
      setCep(response.data);
      setInput("");

    } catch {

      alert("OPS! Erro ao buscar", { input });
      setInput("");
    }
  }

  return (
    <div className="containerM">

      <h1 className="title">Buscador de Endereço</h1>

      <div id="cepInput">
        <input
          className="inputAdress marg-rig"
          type="number"
          placeholder="Digite seu CEP"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />

        <button className="buttonSearch" onClick={handleSearch}>
          <FiSearch size={25} color="#541734" />
        </button>
      </div>

      {Object.keys(cep).length > 0 && (

        <main className='main'>
          <h2 className="h2">CEP: {cep.cep}</h2>

          <span className="span">Logradouro: {cep.logradouro}</span>
          <span className="span">Complemento: {cep.complemento}</span>
          <span className="span">Bairro: {cep.bairro}</span>
          <span className="span">{cep.localidade} - {cep.uf}</span>

        </main>

      )}

    </div>
  )

}
export default Cepfinder;